import React from 'react';
import dayjs from 'dayjs';
import 'twin.macro';
import { ReactComponent as GGELogoBlue } from './assets/gge-logo-b.svg';
import { Button } from '@asu/ui';

import { getBrowser } from '../utils';

// TODO: Заменить, когда появится инжект через webpack
// const systemVersion = PACKAGE_VERSION ?? '-';
const systemVersion = '-';

// TODO: Заменить, когда появится инжект через webpack
// const builtAt = BUILT_AT ?? '-';
const builtAt = '-';

declare global {
  interface Window {
    asubim_handled_errors: Array<{ text: string; filename: string }>;
  }
}
window.asubim_handled_errors = [];

export const addBugReport = (event, hint) => {
  const date = new Date();
  const buitAtDate = new Date(builtAt);

  let text = `Страница ${document.location.href} \n`;
  text += `Время формирования ошибки: ${date.toLocaleString()} \n`;
  text += `Версия сборки: ${systemVersion} \n`;
  text += `Время сборки: ${buitAtDate.toLocaleString()} \n`;
  text += `Пользователь (node_user): ${localStorage.getItem('node_user') || 'не найден'} \n`;
  text += `Пароль (node_pass): ${localStorage.getItem('node_pass') || 'не найден'} \n`;

  text += '\n\nSentry event JSON: \n' + JSON.stringify(event);
  text += '\n\nSentry hint JSON: \n' + JSON.stringify(hint);

  const filename = `${date.toISOString()}.error.log`;

  window.asubim_handled_errors.push({ text, filename });
};

const gitlabBugReport = async (formData, error, componentStack = '') => {
  const browser = getBrowser();

  const text = `### Основные сведения
<pre>
Страница: ${document.location.href}
Время формирования ошибки: ${dayjs().format('HH:mm:ss DD.MM.YYYY')}
Версия Frontend: ${systemVersion} ${dayjs(builtAt).format('HH:mm:ss DD.MM.YYYY')}
Пользователь: ${localStorage.getItem('node_user') || 'не найден'}
Браузер: ${browser.name} ${browser.version}
Контур: Dev
</pre>

### Шаги для повторения
<pre>
${formData.repeatSteps}
</pre>

### Результат
<pre>
${formData.result}
</pre>

### Ожидаемый результат
<pre>
${formData.expectedResult}
</pre>

### Error message
<pre>
${error.message}
</pre>

### Stack trace
<pre>
${error.stack}
</pre>

### React stack trace
<pre>
${componentStack}
</pre>`;

  const epicObj = {
    title: formData.name || 'Неизвестная ошибка',
    description: text,
    labels: 'priority::high,type::bug',
  };

  console.error('error >>>', error);
  console.error('test >>>', text);

  const requestEpic = await fetch('https://gitlab.sintez-at.ru/api/v4/groups/114/epics', {
    method: 'POST',
    headers: {
      'PRIVATE-TOKEN': process.env.NX_APP_GITLABTOKEN,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(epicObj),
  });
  const responseEpicData = await requestEpic.json();

  const issueObj = {
    title: formData.name || 'Неизвестная ошибка',
    description: text,
    labels: 'priority::high,type::bug',
    epic_id: responseEpicData.id,
  };

  const requestIssue = await fetch('https://gitlab.sintez-at.ru/api/v4/projects/324/issues', {
    method: 'POST',
    headers: {
      'PRIVATE-TOKEN': 'glpat-V3zKiPmrD6cpBcS_DpmE',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(issueObj),
  });
  const responseIssueData = await requestIssue.json();
};

export const generateBugReport = () => {
  if (!window.asubim_handled_errors || !window.asubim_handled_errors.length) {
    alert('Список ошибок пуст');
    return;
  }

  window.asubim_handled_errors.forEach(report => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report.text));
    element.setAttribute('download', report.filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};

/**
 * https://reactjs.org/docs/error-boundaries.html
 */
export class GGEErrorBoundary extends React.Component {
  state = { hasError: false, error: null, errorInfo: null, image: null };

  static displayName = 'ErrorBoundary';

  createGitLabReport = formData => {
    gitlabBugReport(formData, this.state.error);
  };

  catchError = event => {
    this.setState(prevState => ({ ...prevState, hasError: true, error: event.error }));
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    this.setState(prevState => ({ ...prevState, hasError: true, error: error, errorInfo: info.componentStack }));
    // gitlabBugReport({}, error, info.componentStack);
  }

  componentDidMount() {
    // if (process.env.NX_SYSTEM_TYPE === 'RZD') this.loadImage('photo1');
    // window.addEventListener('error', this.catchError);
  }

  componentWillUnmount() {
    // window.removeEventListener('error', this.catchError);
  }

  loadImage = imageName => {
    /*  import(`./assets/${imageName}.jpg`).then(image => {
      this.setState(prevState => ({
        ...prevState,
        image: image.default,
      }));
    }); */
  };

  render() {
    if (this.state.hasError) {
      return (
        <div tw="container mx-auto px-4 flex flex-col items-center justify-center min-h-screen py-6 bg-[#eff2f2]">
          <GGELogoBlue tw="h-24 mb-6" />
          <div tw="bg-white p-12">
            <h1 tw="text-grey-900 font-title text-2xl font-medium">В системе произошла ошибка!</h1>
            <div tw="flex space-x-4 mt-4">
              <Button onClick={() => window.location.reload()} tw="py-1 px-1 bg-primary hover:bg-primary-dark/75">
                Перезагрузить страницу
              </Button>
              <Button onClick={generateBugReport} tw="py-1 px-1 bg-gray-400 hover:bg-gray-400/75">
                Получить отчёт об ошибке
              </Button>
            </div>

            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary tw="text-xs text-grey-300 cursor-pointer mt-8 py-4">Подробности ошибки</summary>
              {this.state.error?.toString()}
              <br />
              {this.state.errorInfo}
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
