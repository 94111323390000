import { qParam } from './Service.d';
import { Service } from './Service';
import { ApiCaller } from '../utils/ApiCaller';
import type { AxiosRequestConfig } from 'axios';
import type { ITasktrackerService, ITrackerTaskLabel } from './TasktrackerService.d';
import { ITrackerGroup, ITrackerGroupBody } from './TasktrackerService.d';
export * from './TasktrackerService.d';

const BASE_URL = '/tracker';

export class TasktrackerService implements Service {
  public static getAllAttachmentsInTask(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IAllAttachmentsInTask>(`/tasks/${taskId}/attachments`, config);
  }
  public static createAttachmentInTask(
    taskId: qParam,
    data: ITasktrackerService.Body.ICreateAttachmentInTask,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateAttachmentInTask>(`/tasks/${taskId}/attachments`, data, config);
  }
  public static deleteAttachmentInTask(taskId: qParam, attachmentId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete(`/tasks/${taskId}/attachments/${attachmentId}`, config);
  }
  public static patchAttachmentInTask(
    taskId: qParam,
    attachmentId: qParam,
    data: ITasktrackerService.Body.IPatchAttachmentInTask,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IPatchAttachmentInTask>(
      `/tasks/${taskId}/attachments/${attachmentId}`,
      data,
      config,
    );
  }

  public static getGroupLabels(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetGroupLabels>(`/labels/groups/${groupId}`, config);
  }
  public static createGroupLabel(groupId: qParam, data: ITasktrackerService.Body.ICreateGroupLabel, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateGroupLabel>(`/labels/groups/${groupId}`, data, config);
  }
  public static editGroupLabel(
    groupId: qParam,
    labelId: qParam,
    data: ITasktrackerService.Body.IEditGroupLabel,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IEditGroupLabel>(`/labels/groups/${groupId}/${labelId}`, data, config);
  }

  public static getChecklists(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetChecklists>(`/tasks/${taskId}/checklists`, config);
  }
  public static createChecklist(taskId: qParam, data: ITasktrackerService.Body.ICreateChecklist, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateChecklist>(`/tasks/${taskId}/checklists`, data, config);
  }
  public static createChecklistItem(
    taskId: qParam,
    checklistId: qParam,
    data: ITasktrackerService.Body.ICreateChecklistItem,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateChecklistItem>(
      `/tasks/${taskId}/checklists/${checklistId}/items`,
      data,
      config,
    );
  }
  public static setStatusChecklistItem(taskId: qParam, checklistItemId: qParam, data?: any, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.ISetStatusChecklistItem>(
      `/tasks/${taskId}/checklists/items/${checklistItemId}/status`,
      data,
      config,
    );
  }
  public static deleteChecklists(taskId: qParam, checklistId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete(`/tasks/${taskId}/checklists/${checklistId}`, config);
  }
  public static deleteChecklistItem(taskId: qParam, checklistItemId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete(`/tasks/${taskId}/checklists/items/${checklistItemId}`, config);
  }

  public static createTask(
    groupId: qParam,
    data: Pick<ITasktrackerService.Body.ICreateTask, 'name' | 'assignedUserId' | 'securityLevel' | 'priority'>,
    parentId?: string,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateTask>(`/tasks`, data, {
      ...config,
      params: {
        groupId,
        parentId,
      },
    });
  }
  public static setTaskStatus(taskId: qParam, data: ITasktrackerService.Body.ISetTaskStatus, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ISetTaskStatus>(`/tasks/${taskId}/status`, data, config);
  }
  public static bindProjectToStatus(taskId: qParam, data: ITasktrackerService.Body.IBindProjectToStatus, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IBindProjectToStatus>(`/tasks/${taskId}/project`, data, config);
  }
  public static addLabelToTask(taskId: qParam, labelId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IAddLabelToTask>(`/tasks/${taskId}/labels/${labelId}`, config);
  }
  public static updateLabelsInTask(taskId: qParam, data: Array<string>, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IUpdateLabelsInTask>(`/tasks/${taskId}/labels`, data, config);
  }
  public static deleteLabelFromTask(taskId: qParam, labelId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<void>(`/tasks/${taskId}/labels/${labelId}`, config);
  }
  public static getTask(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetTask>(`/tasks/${taskId}`, config);
  }
  public static updateTask(taskId: qParam, data: ITasktrackerService.Body.IUpdateTask, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IUpdateTask>(`/tasks/${taskId}`, data, config);
  }
  public static getAllTasksWhereUserIsExecutive(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetAllTasksWhereUserIsExecutive>(`/tasks/users/${userId}`, config);
  }

  // Users
  public static getTaskObserversList(taskId: string, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetTaskObserversList>(`/tasks/${taskId}/observers`, config);
  }
  public static addObserverToTask(taskId: qParam, data: Array<qParam>, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IAddUserToGroup>(`/tasks/${taskId}/observers`, data, config);
  }
  public static deleteObserverFromTask(taskId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<ITasktrackerService.IAddUserToGroup>(
      `/tasks/${taskId}/observers?observerUserId=${userId}`,
      config,
    );
  }
  public static createGroup(data: ITasktrackerService.Body.ICreateGroup, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ICreateGroup>(`/groups`, data, config);
  }
  public static archiveGroup(data: Pick<ITrackerGroupBody, 'id' | 'isArchived'>, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IGetGroup>(`/groups/archive`, data, config);
  }
  public static addUserToGroup(groupId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IAddUserToGroup>(`/groups/${groupId}/add?userId=${userId}`, config);
  }
  public static getGroup(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetGroup>(`/groups/${groupId}`, config);
  }
  public static updateGroup(
    groupId: qParam,
    data: Partial<Pick<ITrackerGroupBody, 'name' | 'description' | 'defaultBoardId' | 'projectId'>>,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IUpdateGroup>(`/groups/${groupId}`, data, config);
  }
  public static getGroupInfo(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get(`/groups/${groupId}/info`, config);
  }
  public static removeUserFromGroup(userId: qParam, groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IRemoveUserFromGroup>(`/users/${userId}/groups/${groupId}/leave`, config);
  }
  public static getUserGroupsList(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetUserGroupsList>(`/users/${userId}/groups`, config);
  }
  public static getArchivedUserGroupsList(userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetUserGroupsList>(`/users/${userId}/groups/archive`, config);
  }
  public static getGroupUsersList(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetGroupUsersList>(`/groups/${groupId}/users`, config);
  }
  public static getGroupTasks(groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetGroupTasks>(`/groups/${groupId}/tasks`, config);
  }
  public static getGroupsByProject(projectId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetGroupsByProject>(`/groups/projects/${projectId}`, config);
  }
  public static getTaskbyTaskId(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetTaskbyTaskId>(`/tasks/${taskId}`, config);
  }
  public static updateTaskStatus(taskId: qParam, body: ITasktrackerService.Body.IUpdateTaskStatus, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IUpdateTaskStatus>(`/tasks/${taskId}/status`, body, config);
  }
  public static bindTaskToTask(taskId: qParam, linkedTaskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post(`/tasks/${taskId}/links/${linkedTaskId}`, config);
  }
  public static deleteTasksLink(taskId: qParam, linkedTaskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete(`/tasks/${taskId}/links/${linkedTaskId}`, config);
  }
  public static getLinkedTasks(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetLinkedTasks>(`/tasks/${taskId}/links`, config);
  }
  public static getLinksWithTasks(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get<ITasktrackerService.IGetLinksWithTasks>(`/tasks/${taskId}/links-with`, config);
  }

  public static solutionTask(taskId: qParam, authorId: qParam, data: ITasktrackerService.Body.ISolutionTask, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.ISolutionTask>(`/tasks/${taskId}/solution`, data, config);
  }
  public static updateSolutionTask(
    taskId: qParam,
    authorId: qParam,
    data: ITasktrackerService.Body.IUpdateSolutionTask,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IUpdateSolutionTask>(`/tasks/${taskId}/solution`, data, config);
  }
  public static addAttachmentToSolutionTask(
    taskId: qParam,
    userId: qParam,
    data: ITasktrackerService.Body.IAddAttachmentToSolutionTask,
    config?: AxiosRequestConfig,
  ) {
    return new ApiCaller(BASE_URL).post<ITasktrackerService.IAddAttachmentToSolutionTask>(
      `/tasks/${taskId}/solution/attachments`,
      data,
      config,
    );
  }
  public static deleteAttachmentToSolutionTask(taskId: qParam, attachmentId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete<ITasktrackerService.IDeleteAttachmentToSolutionTask>(
      `/tasks/${taskId}/solution/attachments/${attachmentId}`,
      config,
    );
  }
  public static updateAttachmentToSolutionTask(taskId: qParam, attachmentId: qParam, userId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).patch<ITasktrackerService.IUpdateAttachmentToSolutionTask>(
      `/tasks/${taskId}/solution/attachments/${attachmentId}`,
      config,
    );
  }
  public static closeTask(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post(`/tasks/${taskId}/close`, config);
  }
  public static getChildrenTasks(parentTaskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get(`/tasks/${parentTaskId}/children`, config);
  }
  public static getEventsTasks(taskId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).get(`/events/tasks/${taskId}`, config);
  }
  public static setAnotherUserAsAssignmentPerson(taskId: qParam, id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post(`/tasks/${taskId}/assignment?newAssignedUserId=${id}`, config);
  }
  public static setAnotherUserAsTaskAdmin(taskId: qParam, id: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post(`/tasks/${taskId}/admin?newAdminId=${id}`, config);
  }
  public static setGroupAdmin(userId: qParam, groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).post(`/groups/${groupId}/users/${userId}/administrators`, config);
  }
  public static removeGroupAdmin(userId: qParam, groupId: qParam, config?: AxiosRequestConfig) {
    return new ApiCaller(BASE_URL).delete(`/groups/${groupId}/users/${userId}/administrators`, config);
  }
}
