import React from 'react';
import tw from 'twin.macro';
import { useDropzone } from 'react-dropzone';
import { useFileSystemState } from '../../store';
import { notification } from '@asu/ui';

// eslint-disable-next-line @typescript-eslint/ban-types
type DragAndDropProps = {};

const DragAndDrop = React.memo<DragAndDropProps>(() => {
  const store = useFileSystemState();
  const [isShow, setIsShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onDragStart = () => setIsShow(true);
    const onDragEnd = () => setIsShow(false);

    document.getElementById('root')!.addEventListener('mouseenter', onDragEnd, false);
    document.getElementById('root')!.addEventListener('mouseleave', onDragEnd, false);
    window.addEventListener('mouseup', onDragEnd, false);
    window.addEventListener('dragenter', onDragStart, false);
    window.addEventListener('drop', onDragEnd, false);

    return () => {
      document.getElementById('root')!.removeEventListener('mouseenter', onDragEnd, false);
      document.getElementById('root')!.removeEventListener('mouseeleave', onDragEnd, false);
      document.removeEventListener('mouseup', onDragEnd, false);
      document.removeEventListener('dragenter', onDragStart, false);
      document.removeEventListener('drop', onDragEnd, false);
    };
  }, []);

  const onDrop = React.useCallback(
    acceptedFiles => {
      if (process.env.NX_SYSTEM_TYPE === 'GGE' && !acceptedFiles?.some(file => file.name.includes('.ifc'))) {
        notification.open({
          message: `Можно загружать только файл формата ifc`,
        });
        return;
      }

      store.uploadFile(acceptedFiles[0]);
    },
    [store],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <Container {...getRootProps()} style={{ display: isShow ? 'block' : 'none' }}>
      <input {...getInputProps()} />
      <Zone>
        <Border>{isDragActive ? 'Отпустите файл для загрузки' : 'Поместите файл в рамку для загрузки'}</Border>
      </Zone>
    </Container>
  );
});

const Container = tw.div`absolute inset-0 z-10`;
const Zone = tw.div`margin-top[5.3125rem] absolute inset-0 bg-gray-200 bg-opacity-25 z-10 p-4`;
const Border = tw.div`flex items-center justify-center border-4 border-dashed bg-gray-200 bg-opacity-25 rounded-lg h-full`;

export default DragAndDrop;
