import React from 'react';
import tw from 'twin.macro';
import { styled, keyframes } from '@stitches/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { IconClose } from '../../assets';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

type ContentProps = {
  className?: string;
  children: any;
  style?: any;
  onOpenAutoFocus?: any;
};

const StyledModalContent = ({ children, onOpenAutoFocus, className, ...rest }: ContentProps) => {
  const context = React.useContext<any>(ModalContext);
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay tw="fixed inset-0 bg-black/30 z-40" />
      <StyledContent
        onOpenAutoFocus={onOpenAutoFocus}
        onEscapeKeyDown={() => context?.closeModal()}
        onPointerDownOutside={() => context?.closeModal()}
        tw="bg-white fixed z-50 top-1/2 left-1/2 w-[40vw] max-h-[85vw] max-w-[90vw] transform -translate-x-1/2 -translate-y-1/2 focus:outline-none"
        className={className}
        {...rest}
      >
        {children}
        <Modal.Close>
          <button type="button" aria-label="close" tw="absolute top-0 right-0 flex justify-end outline-none text-white p-4">
            <IconClose tw="transition-colors" />
          </button>
        </Modal.Close>
      </StyledContent>
    </DialogPrimitive.Portal>
  );
};

type ModalTitleProps = { className?: string; children: React.ReactElement | string };
const ModalTitle = ({ className, children }: ModalTitleProps) => (
  <DialogPrimitive.Title tw="px-3 py-2 rounded-t-md bg-primary-dark/90 text-xl text-white font-normal" className={className}>
    {children}
  </DialogPrimitive.Title>
);

type ModalDescriptionProps = { className?: string; children: React.ReactElement | string };
const ModalDescription = ({ className, children }: ModalDescriptionProps) => (
  <DialogPrimitive.Description tw="px-4 py-2" className={className}>
    {children}
  </DialogPrimitive.Description>
);

type ModalContentProps = { className?: string; style?: any; children: any; onOpenAutoFocus?: any };
const ModalContent = ({ className, children, style, onOpenAutoFocus }: ModalContentProps) => (
  <StyledModalContent className={className} style={style} tw="rounded-md min-w-[20vw] min-h-[11vh]" onOpenAutoFocus={onOpenAutoFocus}>
    {children}
  </StyledModalContent>
);

type ModalCloseProps = { children: JSX.Element };
const ModalClose = ({ children }: ModalCloseProps) => {
  const context = React.useContext<any>(ModalContext);
  if (context) return React.cloneElement(children, { onClick: context.closeModal });
  return <DialogPrimitive.Close asChild>{children}</DialogPrimitive.Close>;
};

const AutoModalRoot = React.memo<ModalRootProps>(({ trigger, onOpenChange, children }) => (
  <DialogPrimitive.Root onOpenChange={onOpenChange}>
    <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
    {children}
  </DialogPrimitive.Root>
));

const ModalContext = React.createContext<any>(null);
export const ManualModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return <ModalContext.Provider value={{ isOpen, setIsOpen, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

export const ModifyManualModalProvider = props => {
  const { isOpen, setIsOpen, openModal, closeModal, children } = props;

  if (isOpen && setIsOpen && openModal && closeModal) {
    return <ModalContext.Provider value={{ isOpen, setIsOpen, openModal, closeModal }}>{children}</ModalContext.Provider>;
  }

  return null;
};

export const useManualModalState = () => {
  const context = React.useContext<any>(ModalContext);
  if (context === undefined) throw new Error('useManualModalState must be used within a ManualModalProvider');
  return context;
};

const ManualModalTrigger = ({ children }) => {
  const { openModal } = useManualModalState();
  return React.cloneElement(children, { onClick: openModal });
};

const ManualModalRoot = React.memo<ModalRootProps>(({ trigger, onOpenChange, children }) => {
  const { isOpen } = useManualModalState();

  React.useEffect(() => onOpenChange && onOpenChange(isOpen), [isOpen]);

  return (
    <DialogPrimitive.Root open={isOpen}>
      {trigger && <ManualModalTrigger>{trigger}</ManualModalTrigger>}
      {isOpen && children}
    </DialogPrimitive.Root>
  );
});

type ModalRootProps = {
  manual?: any;
  trigger?: any;
  onOpenChange: any;
  children: any; //React.ReactElement | ((closeModal: any) => React.ReactElement);
  isOpen?: boolean;
  setIsOpen?: boolean;
  openModal?: () => void;
  closeModal?: () => void;
};
const ModalRoot = ({ manual, onOpenChange, children, isOpen, ...rest }: any) => {
  if (manual && isOpen) {
    return (
      // If Modal window has (isOpen, setIsOpen, openModal, closeModal) then give it custom provider for manage inside parent component
      <ModifyManualModalProvider isOpen={isOpen} {...rest}>
        <ManualModalRoot onOpenChange={onOpenChange} {...rest}>
          {children}
        </ManualModalRoot>
      </ModifyManualModalProvider>
    );
  }

  if (manual) {
    return (
      <ManualModalProvider {...rest}>
        <ManualModalRoot onOpenChange={onOpenChange} {...rest}>
          {children}
        </ManualModalRoot>
      </ManualModalProvider>
    );
  }

  return (
    <AutoModalRoot onOpenChange={onOpenChange} {...rest}>
      {children}
    </AutoModalRoot>
  );
};

const Modal = {
  Root: ModalRoot,
  Content: ModalContent,
  Title: ModalTitle,
  Desciprion: ModalDescription,
  Close: ModalClose,
};

export default Modal;
