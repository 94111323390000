import { makeAutoObservable, runInAction } from 'mobx';
import { IProjectResponseBody, ProjectService } from '@asu/services';

export class ProjectStore {
  project: IProjectResponseBody = null;
  isLoading = false;
  redirect = null;

  constructor() {
    makeAutoObservable(this);
  }

  async getProject(id) {
    this.isLoading = true;

    try {
      const projectResponse = await ProjectService.getProject(id).fetch();
      const project = projectResponse.data;

      const ganttResponse = await ProjectService.getModelsGantt(project.id).fetch();
      const gantt = ganttResponse.data;

      const metaResponse = await ProjectService.getMeta(project.id).fetch();
      const meta = metaResponse.data;

      const userResponse = await ProjectService.getAssignedUsers(project.id).fetch();
      const user = userResponse.data;

      const resultProject = {
        ...project,
        ganttModels: gantt,
        meta,
        assignedUsers: user.assignedUsers || [],
      };

      runInAction(() => {
        this.project = resultProject;
        this.isLoading = false;
      });
    } catch (e) {
      console.error('error in getProject >>>', e);

      if (e.response.status === 418) {
        this.redirect = true;
      }

      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  reset() {
    this.project = null;
  }

  resetRedirect() {
    this.redirect = null;
  }
}

const projectStore = new ProjectStore();
export default projectStore;
