interface IUser {
  id: string;
  lastName: string;
  firstName: string;
  middleName: string;
  email: string;
  position: string;
  group: {
    id: string;
    name: string;
    subGroups: string[];
  };
}

interface IUserGroup {
  id: string;
  name: string;
}

export declare namespace IAuthService {
  type IGetUserResponse = IUser;
  type IGetUsersResponse = IUser[];
  type IGetCurrentUserResponse = IUser;
  type IGetGroupsResponse = IUserGroup[];
  type IGetGroupMembersResponse = IUser[];
  type IGetGroupUserResponse = IUserGroup[];
}
