import { useKeycloak } from '@react-keycloak/web';

interface IBlacklistRoles {
  V: TGGEPermissions[];
  M: TGGEPermissions[];
  A: TGGEPermissions[];
  SA: TGGEPermissions[];
}

export type TGGERole = keyof IBlacklistRoles;

const GGE_PERMISSIONS = [
  'CREATE_PROJECT',
  'PROJECT_INFORMATION_EXPERTISE_EDIT',
  'MARK_AS_PRIMARY_MODEL',
  'MARK_AS_ENGINEERING',
  'ADD_USER_AND_ACCEPT_CHANGED',
  'DELETE_USER_EXPERTISE',
  'EDIT_ORGANIZATION_NAME',
  'PROJECT_ORGANIZATION_ADD',
  'EDIT_PROJECT_INFORMATION',
  'RENAME_FILE_FILESYSTEM',
  'DELETE_FILE_FILESYSTEM',
  'ARCHIVE_PROJECT',
  'ROLES_CREATE_PROJECT',
] as const;

export type TGGEPermissions = typeof GGE_PERMISSIONS[number];

const BLACKLIST_GGE_PERMISSIONS_BY_ROLE: IBlacklistRoles = {
  V: [
    'CREATE_PROJECT',
    'PROJECT_INFORMATION_EXPERTISE_EDIT',
    'MARK_AS_PRIMARY_MODEL',
    'MARK_AS_ENGINEERING',
    'ADD_USER_AND_ACCEPT_CHANGED',
    'DELETE_USER_EXPERTISE',
    'EDIT_ORGANIZATION_NAME',
    'PROJECT_ORGANIZATION_ADD',
    'EDIT_PROJECT_INFORMATION',
    'RENAME_FILE_FILESYSTEM',
    'DELETE_FILE_FILESYSTEM',
    'ARCHIVE_PROJECT',
    'ROLES_CREATE_PROJECT',
  ],
  M: [
    'MARK_AS_PRIMARY_MODEL',
    'MARK_AS_ENGINEERING',
    'EDIT_ORGANIZATION_NAME',
    'PROJECT_ORGANIZATION_ADD',
    'ADD_USER_AND_ACCEPT_CHANGED',
    'ROLES_CREATE_PROJECT',
  ],
  A: ['MARK_AS_ENGINEERING'],
  SA: [],
};

type Keys = keyof typeof BLACKLIST_GGE_PERMISSIONS_BY_ROLE;
type Permissions = typeof BLACKLIST_GGE_PERMISSIONS_BY_ROLE[Keys];

interface IUserProfile {
  id: string;
  username: string;
  attributes: any;
  lastName: string;
  firstName: string;
  givenName: string;
  email: string;
  roles: string[];
  groups: string[];
  permissionsBlacklist?: Permissions | null;
}

export const useAuthState = () => {
  const keycloak = useKeycloak();

  const jwtData = keycloak.keycloak.tokenParsed;
  const userProfile: IUserProfile = {
    id: jwtData.sub,
    username: jwtData.name,
    attributes: jwtData.attributes,
    lastName: jwtData.family_name,
    firstName: jwtData.given_name,
    givenName: jwtData.middle_name,
    groups: jwtData.user_groups,
    email: jwtData.email,
    roles: jwtData['global-role'],
    permissionsBlacklist: null,
  };

  if (process.env.NX_SYSTEM_TYPE === 'GGE') {
    let role: TGGERole;

    if (jwtData['global-role']?.includes('ПЛАТФОРМА 3Д ПРОСМОТР')) role = 'V';
    if (jwtData['global-role']?.includes('ПЛАТФОРМА 3Д МОДЕРИРОВАНИЕ')) role = 'M';
    if (jwtData['global-role']?.includes('ПЛАТФОРМА 3Д АДМИНИСТРИРОВАНИЕ')) role = 'A';
    if (jwtData['global-role']?.includes('ПЛАТФОРМА 3Д СУПЕР АДМИНИСТРАТОР')) role = 'SA';

    if (role) userProfile.permissionsBlacklist = BLACKLIST_GGE_PERMISSIONS_BY_ROLE[role];
  }

  return {
    currentUser: { ...userProfile, position: '' },
    logout: keycloak.keycloak.logout,
  };
};
