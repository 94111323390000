import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import { IconClose, IconSnow } from '../../../assets/icons';
import { ElementType, notificationsList } from '../notificationsList';
import dayjs from 'dayjs';

type ItemBlockProps = {
  element: ElementType;
  onDismiss: (id: string) => void;
  onClose: () => void;
};

const ItemBlock = React.memo<ItemBlockProps>(({ element, onDismiss, onClose }) => {
  const [activeNotification, setActiveNotification] = React.useState(false);

  const notificationItem = notificationsList.get(element.name);

  return (
    <BlockWrapper>
      <Block $isActive={activeNotification} onClick={() => setActiveNotification(!activeNotification)}>
        <NotificationField>
          <TitleWrapper>
            <IconSnow tw="text-primary-dark flex-shrink-0 w-3 h-3 mr-2 mt-2" />
            <div tw="flex flex-col">
              <Title>{notificationItem?.title || 'Неподдерживаемый тип уведомления'}</Title>
              <p tw="text-xs text-grey-500">{dayjs(element.timestamp).format('HH:mm DD.MM.YYYY')}</p>
            </div>
          </TitleWrapper>
          <p tw="text-sm break-words">{notificationItem?.body(element) || element.name}</p>
        </NotificationField>
      </Block>
      {activeNotification && notificationItem?.actionUrl && (
        <Basement>
          <Title>Быстрое действие</Title>
          <div tw="mt-2">
            <BottomWrapper>
              <ButtonEnter onClick={onClose} to={notificationItem?.actionUrl(element)}>
                {notificationItem?.buttonTitle}
              </ButtonEnter>
            </BottomWrapper>
          </div>
        </Basement>
      )}
      <ItemCloseButton type="button" tw="absolute right-2 top-2 p-2 hocus:outline-none" onClick={() => onDismiss(element.id)}>
        <IconClose tw="text-black opacity-50 h-2 w-2" />
      </ItemCloseButton>
    </BlockWrapper>
  );
});

const ItemCloseButton: any = tw.button`transition-opacity opacity-0`;
const BlockWrapper = styled.div`
  ${tw`relative w-full mb-3 select-none`}

  &:hover {
    ${ItemCloseButton} {
      ${tw`opacity-100`}
    }
  }
`;
type BlockPropsType = {
  $isActive: boolean;
};
const Block = styled.div<BlockPropsType>`
  ${tw`relative bg-gray-100 border-2 border-gray-200 cursor-pointer`}
  ${({ $isActive }) => ($isActive ? tw`rounded-t-lg` : tw`rounded-lg`)}
`;
const Basement = styled.div`
  ${tw`w-full px-4 py-3 bg-gray-300 border-2 rounded-b-lg border-grey-300`}
`;
const Title = tw.h1`text-base font-bold`;
const TitleWrapper = tw.div`flex items-start mb-1`;
const NotificationField = tw.div`p-4 rounded`;
const BottomWrapper = tw.div`flex flex-row`;
const ButtonEnter = styled(Link)`
  ${tw`height[32px] width[126px] flex items-center justify-center bg-gradient-to-r from-primary-dark to-primary-light rounded-md text-white w-full mr-1`}
`;

export default ItemBlock;
