import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link, matchPath, NavLink, useLocation, useMatch } from 'react-router-dom';

import { BackgroundSideBarImage, GGELogoBlue, RzdLogo } from '../../assets';
import { IconDashboard, IconDocuments, IconFolder, IconGantt, IconReferences, IconTasks, IconWindows } from '../../assets/icons';
import { ProcessList } from '../ProcessList';
import { AccountBlock, DocumentMenuOverlay } from './local-components';
import { TeamOutlined } from '@ant-design/icons';

const SideBar: React.FC = () => {
  const [groupId, setGroupId] = React.useState<string | null>(null);
  const location = useLocation();
  const isProject = useMatch('/project/:projectId/*');
  const isConstructorControl = useMatch('/constructor-control/:projectId');

  const projectParams = matchPath(
    {
      path: '/project/:projectId',
      end: false,
    },
    location.pathname,
  );
  const constructorControlParams = matchPath(
    {
      path: '/constructor-control/:projectId',
      end: false,
    },
    location.pathname,
  );

  /* useEffect(() => {
    const request = TasktrackerService.getGroupsByProject(isProject?.params.projectId);
    if (isProject) {
      (async () => {
        const response = await request.fetch();
        const responseData = response.data;
        setGroupId(responseData[0]?.group.id);
      })();
    }
    return () => {
      request.abort();
    };
  }, [isProject]); */

  return (
    <Wrapper>
      <Container>
        <TopSection to={isProject ? `` : '/'}>
          {process.env.NX_SYSTEM_TYPE === 'RZD' && (
            <ProgramName $isActive={process.env.NX_SYSTEM_TYPE === 'RZD'}>
              <RzdLogo /> AСУ BIM
            </ProgramName>
          )}

          {process.env.NX_SYSTEM_TYPE === 'GGE' && (
            <div tw="flex items-center h-full w-full bg-white">
              <GGELogoBlue tw="mr-8 mt-1" />
            </div>
          )}
        </TopSection>
        <MainSection>
          <Menu>
            {process.env.NX_SYSTEM_TYPE !== 'GGE' && (
              <MenuItem>
                <MenuTitle>Меню системы</MenuTitle>
                <MenuLink to="/" end={true} tw="flex items-center">
                  <IconFolder tw="text-white mt-1" />
                  <MenuText>Мои проекты</MenuText>
                </MenuLink>

                <MenuLink tw="flex items-center my-4" to={`/references`}>
                  <IconReferences tw="h-8 w-8 text-white mt-1" />
                  <MenuText>Справочники</MenuText>
                </MenuLink>

                {process.env.NX_SYSTEM_TYPE === 'RZD' && (
                  <MenuLink tw="flex items-center my-4" to={`/tasktracker/global-workgroups`}>
                    <IconReferences tw="h-8 w-8 text-white mt-1" />
                    <MenuText>Рабочие группы</MenuText>
                  </MenuLink>
                )}
              </MenuItem>
            )}

            {isProject && (
              <div tw="flex-grow bg-white bg-opacity-5 pt-4 pl-7 pb-4 my-4">
                {process.env.NX_SYSTEM_TYPE === 'RZD' && <SubMenuTitle>Меню проекта</SubMenuTitle>}
                <MenuLink tw="flex items-center my-2" to={`/project/${projectParams.params?.projectId}/dashboard`}>
                  <IconDashboard tw="h-8 w-8 text-white mt-1" />
                  <MenuText>Карточка проекта</MenuText>
                </MenuLink>
                <div tw="relative">
                  <MenuLink tw="flex items-center my-4" to={`/project/${projectParams.params?.projectId}/filesystem`}>
                    <IconDocuments tw="h-8 w-8 text-white mt-1" />
                    <MenuText>{process.env.NX_SYSTEM_TYPE !== 'RZD' ? 'Трехмерная модель' : 'Документация'}</MenuText>
                  </MenuLink>
                  <DocumentMenuOverlay projectId={projectParams.params.projectId}>
                    {(isActive, toggle) => (
                      <NewWindowButton $isActive={isActive} onClick={() => toggle()} type="button">
                        <IconWindows tw="height[6px] width[6px] text-white" />
                      </NewWindowButton>
                    )}
                  </DocumentMenuOverlay>
                </div>
                <MenuLink
                  tw="flex items-center my-4"
                  to={
                    process.env.NX_SYSTEM_TYPE === 'RZD'
                      ? `/project/${projectParams.params?.projectId}/role`
                      : `/project/${projectParams.params?.projectId}/role-gge`
                  }
                >
                  <TeamOutlined tw="text-white mt-1" style={{ fontSize: '32px' }} />
                  <MenuText>{process.env.NX_SYSTEM_TYPE === 'GGE' ? 'Список экспертов' : 'Проектные роли'}</MenuText>
                </MenuLink>

                {process.env.NX_SYSTEM_TYPE === 'RZD' && (
                  <MenuLink tw="flex items-center my-4" to={`/project/${projectParams.params?.projectId}/gantt`}>
                    <IconGantt tw="h-7 w-7 text-white mt-1" />
                    <MenuText>Сетевой график</MenuText>
                  </MenuLink>
                )}
                {process.env.NX_SYSTEM_TYPE === 'RZD' && (
                  <MenuLink tw="flex items-center my-4" to={`/project/${projectParams.params?.projectId}/tasks`}>
                    <IconTasks tw="h-8 w-8 text-white mt-1" />
                    <MenuText>Задачи проекта</MenuText>
                  </MenuLink>
                )}
                {process.env.NX_SYSTEM_TYPE === 'RZD' && groupId && (
                  <MenuLink tw="flex items-center my-4" to={`project/${projectParams?.params?.projectId}/tasktracker/${groupId}`}>
                    <IconReferences tw="h-8 w-8 text-white mt-1" />
                    <MenuText>Список задач</MenuText>
                  </MenuLink>
                )}
                {process.env.NX_SYSTEM_TYPE === 'RZD' && (
                  <>
                    <SubMenuTitle>Стадии проекта</SubMenuTitle>
                    <ProcessList />
                  </>
                )}
              </div>
            )}
            {isConstructorControl && (
              <div tw="flex-grow bg-white bg-opacity-5 pt-4 pl-7 pb-4 my-4">
                <SubMenuTitle>Справочная информация</SubMenuTitle>
                <MenuLink tw="flex items-center my-4" to={`/constructor-control/${constructorControlParams.params?.projectId}`}>
                  <IconDashboard tw="h-8 w-8 text-white mt-1" />
                  <MenuText>Контроль строительства</MenuText>
                </MenuLink>
                <SubMenuTitle>Процессы</SubMenuTitle>
              </div>
            )}
          </Menu>
          {process.env.NX_SYSTEM_TYPE === 'RZD' && (
            <Footer>
              <AccountBlock />
            </Footer>
          )}
        </MainSection>
      </Container>
    </Wrapper>
  );
};

const Wrapper = tw.aside`padding-right[270px]`;
const Container = styled.div`
  ${tw`width[270px] fixed overflow-y-auto top-0 flex flex-col flex-shrink-0 h-screen`}

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d1d5db;
    border: 2px solid #fff;
    border-radius: 20px;
    opacity: 0.2;
  }
`;
const ProgramName = styled.h1<any>`
  ${tw`font-size[1.375rem] text-white font-title relative flex items-center gap-5 font-semibold`}
  ${({ $isActive }: any) => $isActive && tw``}
`;
const TopSection = tw(Link)`height[80px] flex flex-shrink-0 justify-center items-center bg-primary`;
const MainSection = styled.section`
  // background-image: url(${BackgroundSideBarImage});

  ${tw`flex flex-col bg-grey-900 justify-between flex-1 w-full bg-bottom bg-no-repeat bg-cover`};
`;

const Menu = tw.main`flex-1 flex flex-col`;
const MenuItem = tw.div`pt-7 pl-7`;
const MenuTitle = tw.h2`font-title text-sm text-grey-300 opacity-25 mb-4`;
const MenuText = tw.p`ml-3 text-lg font-medium text-white`;

const SubMenuTitle = tw.h2`font-title color[#D2D3D4] text-sm opacity-25 mt-4`;

const Footer = tw.footer`mt-auto px-4`;

interface MenuLinkProps {
  readonly $isActive?: boolean;
}
const MenuLink = styled(NavLink)<MenuLinkProps>`
  ${tw`relative`}
  ${tw`after:(content[''] absolute right-0 width[3px] height[150%] bg-secondary-light hidden)`}
  ${({ $isActive }) => $isActive && tw`after:block`}

  &.active {
    ${tw`after:block`}
  }
`;

interface NewWindowButtonProps {
  readonly $isActive?: boolean;
}
const NewWindowButton = styled.button<NewWindowButtonProps>`
  ${tw`absolute top-1/2 transform -translate-y-1/2 -left-7 w-3 h-12 flex items-center justify-center bg-grey-500 bg-opacity-50 hover:(bg-grey-500 bg-opacity-80) border border-white border-opacity-10 rounded-r-sm transition duration-150 hocus:outline-none opacity-50`}
  ${({ $isActive }) => $isActive && tw`bg-primary bg-opacity-75 opacity-75 hover:(bg-primary-light bg-opacity-75)`}
`;

export default SideBar;
