import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMatch, useNavigate } from 'react-router-dom';
import { useProjectState } from './hook';

const WithProjectStoreWrapper = observer(({ children }: any) => {
  const store = useProjectState();
  const navigate = useNavigate();
  const matchUrl = useMatch('/project/:projectId/*');
  const projectId = matchUrl?.params?.projectId;

  React.useLayoutEffect(() => {
    if (store.redirect) {
      navigate(`/`);
      store.resetRedirect();
    }
  }, [navigate, store.redirect]);

  React.useEffect(() => {
    if (projectId && !store.project) {
      store.getProject(projectId);
      return;
    }
    if (projectId && store.project?.id !== projectId) {
      store.getProject(projectId);
      return;
    }
    store.reset();
  }, [matchUrl, projectId, store]);

  return children;
});

export default WithProjectStoreWrapper;
